import axios, { AxiosInstance, AxiosResponse } from "axios";
import { Feedback } from "@/interfaces/feedback";

class Http {
  private instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: process.env.VUE_APP_BASE_URL,
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  }

  async feedback(payload: Feedback): Promise<AxiosResponse> {
    return await this.instance.post("/feedback", payload);
  }
}

export const http = new Http();
