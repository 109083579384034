
import { defineComponent } from "vue";
import { http } from "@/http";
import { Feedback } from "@/interfaces/feedback";
import { AxiosError } from "axios";
import Toast, { ToastContent } from "@/components/Toast.vue";

interface Error {
  value?: string;
  msg: string;
  param?: string;
  location?: string;
}

const toasts: ToastContent[] = [];

export default defineComponent({
  name: "Write Us",
  components: {
    Toast,
  },
  data() {
    return {
      toasts,
      firstName: null,
      lastName: null,
      email: null,
      subject: null,
      message: null,
      isDisabled: false,
    };
  },
  methods: {
    checkForm: async function(e: Event) {
      try {
        e.preventDefault();
        this.toasts = [];
        this.isDisabled = true;
        const feedback: Feedback = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          subject: this.subject,
          message: this.message,
        };
        await http.feedback(feedback);
        this.toasts = [{ level: "bg-success", msg: "Verschickt!" }];
        this.resetForm();
      } catch (error) {
        this.handleError(error);
      } finally {
        this.isDisabled = false;
      }
    },
    handleError(axiosError: AxiosError) {
      const response = axiosError.response;
      const status = response?.status;
      const data = response?.data;
      switch (status) {
        case 400: {
          const errors: Error[] = data.errors;
          errors.forEach((error) => {
            this.toasts.push({
              level: "bg-warning",
              msg: error.msg,
            });
          });
          break;
        }
        default: {
          this.toasts.push({
            level: "bg-danger",
            msg: "Server nicht erreichbar!",
          });
          break;
        }
      }
    },
    resetForm() {
      this.firstName = null;
      this.lastName = null;
      this.email = null;
      this.subject = null;
      this.message = null;
    },
  },
});
