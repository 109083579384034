
import { defineComponent } from "vue";

type Level = "bg-danger" | "bg-warning" | "bg-success";

export interface ToastContent {
  msg: string;
  level: Level;
}

export default defineComponent({
  props: {
    toasts: {
      type: Array,
      required: true,
    },
  },
});
